import ShoppingCartLogo from '@/assets/images/ShoppingCart.png';
import { OrderTypeEnum } from '@/common';
import { ItemsPerPageDropdown, Pagination, TsuLoading } from '@/common/components';
import { ProductStatusEnum } from '@/common/enums/productStatusEnum';
import { useCustomerTheme } from '@/common/theme';
import { FavoritesProvider } from '@/features/favorites/favorites.context';
import { CategoriesContentLoader } from '@/features/product-category/components/CategoriesContentLoader';
import { ProductProvider } from '@/features/product/product.context';
import { AppLayout } from '@/layouts/AppLayout';
import { useApolloClient } from '@apollo/client';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  List,
  Snackbar,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import {
  GetProductDocument,
  GetProductQuery,
  useGetCategoriesQuery,
  useGetCategoryLazyQuery,
  useBulkCartCsvUploadMutation,
  useGetCurrentUserQuery,
} from '../../../graphql';
import { useShoppingCart } from '../../cart/shopping-cart.context';
import { useChangeCustomerEffect } from '../../customer/hooks/use-change-customer';
import { CategoryContextProvider } from '../../product-category/category.context';
import { CategoryItem } from '../../product-category/components/CategoryItem';
import { CategoryItemContainer } from '../../product-category/components/CategoryItemContainer';
import { ArchiveOrderOptions } from '../components/ArchiveOrderOptions';
import ShopItem from '../components/ShopItem';
import ShopItemDialog from '../components/ShopItemDialog';
import { ShopItemLoader } from '../components/ShopItemLoader';
import { useShopParams } from '../hooks/use-shop-params';
import { useShopProducts } from '../hooks/use-shop-products';
import _ from 'lodash';

function Shop() {
  const client = useApolloClient();
  const { incrementItem } = useShoppingCart();
  const [mutate, {error: UploadCsvError}] = useBulkCartCsvUploadMutation();

  const {
    searchValue,
    setSearchValue,
    parentCategoryId,
    setParentCategoryId,
    selectedCategoryId,
    selectCategoryId,
    setCategoryIds,
    clearSearchParams,
  } = useShopParams({
    onSearchChange() {
      // clearSelectedCategory();
      setSelectedProductId(undefined);
    },
  });

  const [productStatusId, setProductStatusId] = useState<ProductStatusEnum>();
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [selectedProductId, setSelectedProductId] = useState<number>();
  const [shopItemDialogOpen, setShopItemDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const customerTheme = useCustomerTheme();
  const {
    data: currentUserData,
    loading: isUserLoading,
    refetch: currentUserRefetch,
  } = useGetCurrentUserQuery({ notifyOnNetworkStatusChange: true });

  useChangeCustomerEffect(() => currentUserRefetch());

  // Custom hook that handles the Products Query
  const {
    products,
    refetch: refreshProducts,
    totalCount,
    loading: areProductsLoading,
  } = useShopProducts({
    categoryId: selectedCategoryId,
    statusId: productStatusId,
    searchValue,
    page,
    itemsPerPage,
  });
  // State derived from product
  const getSelectedProduct = (id: number) => products.find((p) => p.productId === id);
  var noParentProds = products.filter(p => p.parentId == null);
  const canShowPagination = noParentProds.length > 0 && itemsPerPage;

  // Query that loads the Child Categories of a Parent Category ID
  const {
    data: childCategoriesQuery,
    loading: areChildCategoriesLoading,
    refetch: refreshCategories,
  } = useGetCategoriesQuery({
    variables: { catId: parentCategoryId },
    notifyOnNetworkStatusChange: true,
  });
  // State derived from child categories
  const childCategories = childCategoriesQuery?.childCategories ?? [];
  const getChildCategory = (id: number) => childCategories.find((cc) => cc.categoryId === id);

  // Query that loads the full Selected Category object (from an ID)
  const [getCategory, { data: categoryQuery, loading: isCategoryLoading }] =
    useGetCategoryLazyQuery();
  // State derived from category
  const selectedCategory = selectedCategoryId ? categoryQuery?.category : null;
  // console.log('selectedCategoryId:', selectedCategoryId, 'selectedCategory:', selectedCategory);
  const isArchiveOrderCategory = selectedCategory?.orderTypeId === OrderTypeEnum.Archive;

  // Query that loads the full Parent Category object (from an ID)
  const [getParentCategory, { data: parentCategoryQuery, loading: isParentCategoryLoading }] =
    useGetCategoryLazyQuery();
  // State derived from parent category
  const parentCategory = parentCategoryId ? parentCategoryQuery?.category : null;

  useEffect(() => {
    if (selectedCategoryId) {
      getCategory({ variables: { catId: selectedCategoryId } });
    }
    if (parentCategoryId) {
      getParentCategory({ variables: { catId: parentCategoryId } });
    }
  }, []);

  useChangeCustomerEffect(() => {
    refreshCategories();
    refreshProducts();
    clearSearchParams();
  });

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  useEffect(() => {
    refreshProducts();
  }, [page]);

  async function handleAddToCart(id: number, amount: number) {
    let product = getSelectedProduct(id);
    if (!product) {
      // Product can be undefined beecause it was chosen from a product's attributes
      // For now, imperatively fetch the product via GraphQL
      product = await client
        .query<GetProductQuery>({ query: GetProductDocument, variables: { productId: id } })
        .then<any>((res) => res.data.product);
      // If the product could not be found then just exit,
      // however this should never happen.
      if (!product) return;
    }
    setSelectedProductId(id);
    // Clamp the amount between min order quantity (or 1) and stock available
    // The quantity will either be:
    //  - amount passed (override),
    //  - minimum order quantity (product's configured lowest),
    //  - 1 (hardcoded fallback value)
    incrementItem(
      id,
      product.code,
      Math.max(Math.min(amount, product.stockAvailable), product.minOrderQuantity ?? 0)
    );
    setSnackbarOpen(true);
  }


  function handleShopItemClick(id: number) {
    setSelectedProductId(id);
    setShopItemDialogOpen(true);
  }

  function handleShopItemDialogClose() {
    setShopItemDialogOpen(false);
    setSelectedProductId(undefined);
  }

  function handleCategoryItemClick(catId: number) {
    // Reset page to zero (sometimes you selected a page in a category, then you switch to another category)
    setPage(0);
    // Unset selected product ID, because when selected category changes, the list of products also changes,
    // and therefore the getSelectedProduct() will return undefined (which may cause an error).
    setSelectedProductId(undefined);

    // Always update the Selected Category ID (because we clicked it)
    const categoryIdUpdates: Record<string, number | undefined> = { catId };

    // Now we need to check if this category has any children
    const category = getChildCategory(catId);
    // If it has children then we want to ALSO set this category as the Parent Category
    if (category && category.childCategoriesCount > 0) {
      // If it has children then update the Parent Category ID as well
      categoryIdUpdates.parentCatId = catId;
      // And refetch the Parent Category Query with this Category's ID,
      // so that our template displays the new Parent Category ID
      getParentCategory({ variables: { catId } });
    }

    // Always refetch the Selected Category Query,
    // so that our template can display the currently selected category's name
    getCategory({ variables: { catId } });

    // Commit SearchParams changes to Shop Params custom hook
    setCategoryIds(categoryIdUpdates);
  }

  function handleCategoryBack() {
    // The back function can only be called if a parentCategory exists,
    // therefore we need to get the parentCategory's parent (i.e. grand parent)
    // This parentId may be NULL if the category is at the top level (no more parent)
    const parentCategoryId = parentCategory?.parentId ?? undefined;
    setParentCategoryId(parentCategoryId);
    if (parentCategoryId) getParentCategory({ variables: { catId: parentCategoryId } });
  }

  function handleDeselectCategory() {
    setPage(0); // see handleCategoryItemClick for the explanation
    selectCategoryId(undefined);
  }

  function handleClearSearch() {
    setPage(0);
    setSearchValue('');
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const pagination = (
    <Box mb={2} display="flex">
      <ItemsPerPageDropdown itemsPerPage={itemsPerPage} onChange={setItemsPerPage} />
      <Box mx={1} />
      {canShowPagination && (
        <Pagination page={page} count={Math.ceil(totalCount / itemsPerPage)} onChange={setPage} />
      )}
    </Box>
  );

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const csv_file = event.target.files?.[0];
    if (csv_file) {
      try {
        const response = await mutate({ variables: {
          input: {
            file: csv_file,
            cartId: currentUserData?.currentUser?.shoppingCart?.id ?? ""
          } 
        }});
        // Handle the response from the GraphQL server
        currentUserRefetch();
        window.alert(response.data?.bulkCartCsvUpload);
      } catch (error) {
        // Handle errors
        console.error(error);
      }
    }
  }

  return (
    <FavoritesProvider>
      <AppLayout withSearchBar>
        <Container sx={{ flexGrow: 1 }} maxWidth={false}>
          {currentUserData?.currentUser?.role?.adminRole ?
            <div style={{position: 'relative', height: '40px', marginBottom: '10px'}}>
              <Button component="label" variant="contained" sx ={{backgroundColor: customerTheme.pageHeader.cart, right: '50px', top: '0px', position: 'absolute', margin: '5px'}}>
                Import from Csv
                <VisuallyHiddenInput type="file" accept=".csv" onChange={handleFileUpload}/>
              </Button>
            </div>
          :
            <>
            </>
          }
          
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3} xl={2} />
            <Grid item xs={12} lg={9} xl={10}>
              <Stack pl={2} direction="row" justifyContent="start" spacing={1}>
                {selectedCategoryId && selectedCategory ? (
                  <Chip
                    label={'Category: ' + selectedCategory.name}
                    onDelete={handleDeselectCategory}
                  />
                ) : null}
                {searchValue !== '' && !areProductsLoading ? (
                  <Chip
                    label={`${totalCount} result${
                      totalCount > 1 || totalCount == 0 ? 's' : ''
                    } for "${searchValue}"`}
                    onDelete={handleClearSearch}
                  />
                ) : null}
              </Stack>
            </Grid>
            <Grid item xs={12} lg={3} xl={2}>
              <Box hidden={!isArchiveOrderCategory} mb={2}>
                <ArchiveOrderOptions />
              </Box>
              <Card>
                <CardContent sx={{ px: 2 }}>
                  <TsuLoading
                    loading={areChildCategoriesLoading}
                    loader={<CategoriesContentLoader />}
                  >
                    {parentCategory ? (
                      <Stack direction="row" alignItems="center">
                        <IconButton onClick={handleCategoryBack}>
                          <ArrowBackIcon />
                        </IconButton>
                        <Typography>{parentCategory.name}</Typography>
                      </Stack>
                    ) : (
                      <Typography variant="h5" pl={2}>
                        Categories
                      </Typography>
                    )}
                    <List>
                      <CategoryItemContainer onCategoryItemClick={handleCategoryItemClick}>
                        {childCategories.map((c, i) => (
                          <CategoryItem
                            key={i}
                            {...c}
                            selected={selectedCategoryId === c.categoryId}
                          />
                        ))}
                      </CategoryItemContainer>
                    </List>
                  </TsuLoading>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={9} xl={10}>
              <Grid container spacing={0}>
                {selectedCategoryId && !totalCount && !areProductsLoading && !searchValue && (
                  <Box pl={2}>
                    <Typography>No products under this category.</Typography>
                    <Button variant="contained" onClick={() => refreshProducts()} sx={{ mt: 2 }}>
                      Refresh
                    </Button>
                  </Box>
                )}
                {!areProductsLoading
                  && products.filter(p => p.parentId == null && p.orderTypeId != 4 && !p.hidden).map((product, i) => (
                    <ShopGridItem key={i}>
                      <ProductProvider product={product}>
                        <ShopItem
                          onClick={() => handleShopItemClick(product.productId)}
                          onAddToCart={handleAddToCart}
                        />
                      </ProductProvider>
                      
                    </ShopGridItem>
                  ))
                }
                {areProductsLoading &&
                  Array(9)
                    .fill(0)
                    .map((_, i) => (
                      <ShopGridItem key={i}>
                        <ShopItemLoader />
                      </ShopGridItem>
                  ))
                }
              </Grid>
              <Box my={1} />
              <Box hidden={areProductsLoading || !products.length}>{pagination}</Box>
            </Grid>
          </Grid>
        </Container>

        {selectedProductId && getSelectedProduct(selectedProductId) && (
          <ProductProvider product={getSelectedProduct(selectedProductId)!}>
            <ShopItemDialog
              open={shopItemDialogOpen}
              onClose={handleShopItemDialogClose}
              onAddToCart={handleAddToCart}
            />
          </ProductProvider>
        )}

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          autoHideDuration={2000}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="success"
            variant="outlined"
            sx={{ width: '100%', backgroundColor: 'white' }}
            icon={
              <img
                src={ShoppingCartLogo}
                alt="Shopping Cart"
                style={{ width: 24, objectFit: 'contain' }}
              />
            }
          >
            <Stack direction="row" alignItems="center">
              <Typography>Item added to Cart.</Typography>
              <Button href="/cart" variant="text">
                View Cart
              </Button>
            </Stack>
          </Alert>
        </Snackbar>
      </AppLayout>
    </FavoritesProvider>
  );
}

function ShopGridItem({ children }: PropsWithChildren<{}>) {
  return (
    <Grid
      item
      sx={{
        marginLeft: {
          xs: 0,
          sm: 2,
        },
        marginRight: {
          xs: 0,
          sm: 2,
        },
        marginBottom: 4,
        marginTop: {
          xs: 2,
          sm: 0,
        },
      }}
      xs={12}
      sm={5}
      lg={3}
      xl={2.5}
    >
      {children}
    </Grid>
  );
}

export default function WrappedShop() {
  return (
    <CategoryContextProvider>
      <Shop />
    </CategoryContextProvider>
  );
}
